import axios from "axios";
import { SANITY_TOKEN, SANITY_BASE_URL } from "./constants";

const caller = axios.create({
	baseURL: SANITY_BASE_URL,
	timeout: 7000,
	headers: {
		Authorization: `Bearer ${SANITY_TOKEN}`,
		"Content-type": "application/json"
	}
});

export const registerNewsletter = async (email, filter = "") => {
	const mutations = [
		{
			createOrReplace: {
				_type: "newsletter",
				email,
				search: filter.trim()
			}
		}
	];

	try {
		const res = await caller.post(
			"/v1/data/mutate/production",
			JSON.stringify({ mutations })
		);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const enterRequest = async values => {
	const mutations = [
		{
			createOrReplace: {
				_type: "request",
				...values
			}
		}
	];

	try {
		const res = await caller.post(
			"/v1/data/mutate/production",
			JSON.stringify({ mutations })
		);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};
