import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Request from "../components/Request";

const Hero = styled.h2`
	color: #4f5953;
	font-size: 1.5em;
	font-weight: normal;
	font-style: normal;
	line-height: 1.3125em;
	text-align: center;
	margin-top: 0;
`;

const RequestPage = () => {
	return (
		<Layout>
			<SEO title="Anfrage" />
			<Hero>
				Sie sind ein Kleinunternehmen oder kennen eines, welches Hilfe beim
				Umsatz benötigt?
				<br />
				Bewerben Sie sich oder das Unternehmen und werden Sie gelistet!
			</Hero>
			<Request />
		</Layout>
	);
};

export default RequestPage;
