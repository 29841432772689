import React from "react";
import styled from "styled-components";

const Text = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 1.125em;
	line-height: 1.75em;
	color: #4f5953;
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		text-align: center;
	}
`;

const DescriptiveText = ({ children, ...rest }) => {
	return <Text {...rest}>{children}</Text>;
};

export default DescriptiveText;
