import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
  }
  html {
    background-color: #BED8C9;
    font-size: 10px;
  }

  body {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
  }

  html,body {
    height: 100vh;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--red);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: #587062 var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: #587062 ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  h1 {
    font-weight: normal;
    font-style: normal;
    font-size: 6rem;
    color: #363D39;
    line-height: 1.75em;
  }

  a {
    color: #363D39;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .micon {
    cursor: pointer;
  }

  .micon:hover {
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(255, 255, 255, .5));
  filter: drop-shadow( 3px 3px 2px rgba(255, 255, 255, .5));
  }

`;

export default GlobalStyles;
