import { gql } from "@apollo/client";

export const Q_LISTINGS_TODAY = gql`
	query Q_LISTINGS_TODAY($today: DateTime) {
		allListing(where: { releaseDate: { eq: $today } }) {
			_id
			company
			slug {
				current
			}
			addressStreet
			addressZip
			addressCity
			addressCountry
			phone
			email
			openingtimes
			website
			pitch
			releaseDate
		}
	}
`;
