import React from "react";
import styled from "styled-components";
import Hamster from "../newhamster-0001.png";

const HamImg = styled.img`
	width: 10vw;
	height: auto;
	@media only screen and (max-width: 1024px) {
		width: 20vw;
	}
`;

const HamsterImage = () => <HamImg src={Hamster} alt="Hamster logo" />;

export default HamsterImage;
