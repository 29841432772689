import React from "react";
import styled from "styled-components";

const Foot = styled.footer`
	margin-top: 2rem;
	padding-bottom: 0.5rem;
	height: 2em;
	text-align: center;
	color: #4f5953;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
`;

const Footer = () => (
	<Foot>
		© {new Date().getFullYear()}, by Robin Kretzschmar &amp; Natalie Schumann
	</Foot>
);

export default Footer;
