import React from "react";
import styled from "styled-components";

const Boox = styled.div`
	background: #587062;
	box-shadow: 4px 4px 4px rgba(190, 216, 201, 0.25);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	margin-left: 2em;
	margin-right: 2em;
	padding: 1em;
	padding-top: 0;
	color: #f3fdf4;
	line-height: 2rem;
	font-size: 0.875em;
	font-style: normal;
	font-weight: normal;
	@media only screen and (max-width: 1024px) {
		margin-left: 0;
		margin-right: 0;
		margin-top: 1em;
		margin-bottom: 1em;
		max-width: 100%;
		min-width: initial;
	}
`;

const DarkBox = ({ children, ...rest }) => {
	return <Boox {...rest}>{children}</Boox>;
};

export default DarkBox;
