import React, { useEffect, useState } from "react";
import Listing from "./Listing";
import EmptyListing from "./EmptyListing";
import { useQuery } from "@apollo/client";
import { Q_LISTINGS_TODAY } from "../util/gqlQueries";

const Listings = ({ asOfDate, filter = "" }) => {
	const [loadedListings, setLoadedListings] = useState([]);
	const { data: all } = useQuery(Q_LISTINGS_TODAY, {
		variables: {
			today: asOfDate
		}
	});

	useEffect(() => {
		setLoadedListings((all && all.allListing) || []);
	}, [all]);

	const filterByZip = l => {
		if (filter && filter.length > 2) {
			const lwrVal = filter.toLowerCase();
			return (
				l.addressZip === filter ||
				l.addressZip.startsWith(filter) ||
				l.addressCity.toLowerCase() === lwrVal ||
				l.addressCity.toLowerCase().startsWith(lwrVal)
			);
		} else {
			return false;
		}
	};

	return (
		<div>
			{loadedListings.filter(filterByZip).map(l => (
				<Listing item={l} key={l._id} />
			))}
			{loadedListings.filter(filterByZip).length <= 0 && (
				<EmptyListing filter={filter} />
			)}
		</div>
	);
};

export default Listings;
