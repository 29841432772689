import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const HeadWrap = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding: 1.45rem 1.0875rem;
`;

const H1 = styled.h1`
  margin: 0;
  text-align: center;
`;

const Link = styled.a`
  text-decoration: none;
`;

const Header = ({ siteTitle }) => (
  <header>
    <HeadWrap>
      <H1>
        <Link href="/">{siteTitle}</Link>
      </H1>
    </HeadWrap>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
