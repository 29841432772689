import React from "react";
import { Link } from "@reach/router";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ImpressumPage = () => (
	<Layout>
		<SEO title="Impressum" />
		<Wrapper>
			<h2>Impressum</h2>

			<h3>Angaben gemäß § 5 TMG</h3>
			<p>
				Robin Kretzschmar
				<br />
				Schafweide 67
				<br />
				68167 Mannheim
			</p>

			<h3>Kontakt</h3>
			<p>
				Telefon: 0621 43700950
				<br />
				E-Mail: info@hamster-shopping.com
			</p>

			<h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h3>
			<p>
				Robin Kretzschmar
				<br />
				Schafweide 67
				<br />
				68167 Mannheim
			</p>

			<Link to="/">Zurück zur Startseite</Link>
		</Wrapper>
	</Layout>
);

export default ImpressumPage;
