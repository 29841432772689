import React from "react";
import styled from "styled-components";
import BigBox from "./BigBox";

const H1 = styled.h1`
	margin-top: 0;
	margin-bottom: 0.5em;
	text-align: center;
`;

const H5 = styled.h5`
	margin-top: 0;
	margin-bottom: 0.5em;
	text-align: center;
`;

const EmptyListing = ({ filter = "" }) => {
	return (
		<BigBox>
			{filter.length > 2 && (
				<React.Fragment>
					<H1>{`Kein Eintrag zu "${filter}" gefunden!`}</H1>
					<H5>Trage gerne ein Unternehmen für diese Stadt ein =)</H5>
				</React.Fragment>
			)}
			{filter.length <= 2 && (
				<H1>{`Bitte Postleitzahl oder Stadt eingeben`}</H1>
			)}
		</BigBox>
	);
};

export default EmptyListing;
