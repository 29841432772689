import React from "react";
import "./App.css";
import { client } from "./util/apollo";
import { ApolloProvider } from "@apollo/client";
import "normalize.css";
import { Location, Router } from "@reach/router";
import HomePage from "./pages/index";
import ImpressumPage from "./pages/impressum";
import PrivacyPage from "./pages/datenschutz";
import RequestPage from "./pages/anfrage";
import { PageProvider } from "./util/pageContext";
import OnRouteChangeWorker from "./components/OnRouteChangeWorker";

const reducer = (state, action) => {
  switch (action.type) {
    case "NEWSLETTER_SIGNUP":
      return {
        ...state,
        nsu: action.payload
      };
    default:
      return state;
  }
};

const OnRouteChange = ({ action }) => (
  <Location>
    {({ location }) => (
      <OnRouteChangeWorker location={location} action={action} />
    )}
  </Location>
);
function App() {
  return (
    <ApolloProvider client={client}>
      <PageProvider initialState={{ nsu: false }} reducer={reducer}>
        <Router>
          <HomePage path="/" />
          <ImpressumPage path="impressum" />
          <PrivacyPage path="datenschutz" />
          <RequestPage path="anfrage" />
        </Router>
        <OnRouteChange
          action={() => {
            window.scrollTo(0, 0);
          }}
        />
      </PageProvider>
    </ApolloProvider>
  );
}

export default App;
