import React from "react";
import styled from "styled-components";

const Boox = styled.div`
	background: #a6bdb0;
	box-shadow: 2px 2px 4px rgba(143, 162, 151, 0.25);
	display: flex;
	flex-direction: column;
	margin-top: 2em;
	margin-bottom: 1em;
	padding: 1em;
	width: 100%;
	@media only screen and (max-width: 1024px) {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
		width: auto;
	}
`;

const BigBox = ({ children, ...rest }) => {
	return <Boox {...rest}>{children}</Boox>;
};

export default BigBox;
