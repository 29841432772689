import React, { useState } from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import { enterRequest } from "../util/rest";
import BigBox from "./BigBox";
import Textbox from "./Textbox";
import Textarea from "./Textarea";
import Button from "./Button";
import HamsterImage from "./HamsterImage";

const Content = styled.div`
	display: flex;
	align-items: flex-start;
	@media only screen and (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		margin-left: 1em;
		margin-right: 1em;
	}
`;

const FieldView = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const FieldList = styled.ul`
	list-style-type: none;
	padding: 0;
	max-width: 800px;
	margin: 0 auto;
	& > li:not(:last-child) {
		margin-bottom: 1em;
	}
`;

const Field = styled.li`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@media only screen and (max-width: 1024px) {
		text-align: center;
		justify-content: center;
	}
`;

const ButtonField = styled(Field)`
	justify-content: center;
`;

const FieldLabel = styled.label`
	flex: 1 0 120px;
`;

const DarkTB = styled(Textbox)`
	background: #89a193;
	flex: 1 0 120px;
`;

const DarkTA = styled(Textarea)`
	background: #89a193;
	flex: 1 0 120px;
`;

const H1 = styled.h1`
	@media only screen and (max-width: 1024px) {
		font-size: 4rem;
	}
`;

const PrivacyText = styled.p`
	font-size: 0.75em;
`;

const ThankBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-left: 20%;
	padding-right: 20%;
	@media only screen and (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

const Request = () => {
	const [isSent, setIsSent] = useState(false);
	const [company, setCompany] = useState("");
	const [addressStreet, setAddressStreet] = useState("");
	const [addressZip, setAddressZip] = useState("");
	const [addressCity, setAddressCity] = useState("");
	const [addressCountry, setAddressCountry] = useState("Deutschland");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [url, setUrl] = useState("");
	const [pitch, setPitch] = useState("");

	const companyChanged = e => setCompany(e.target.value);
	const streetChanged = e => setAddressStreet(e.target.value);
	const zipChanged = e => setAddressZip(e.target.value);
	const cityChanged = e => setAddressCity(e.target.value);
	const countryChanged = e => setAddressCountry(e.target.value);
	const phoneChanged = e => setPhone(e.target.value);
	const emailChanged = e => setEmail(e.target.value);
	const urlChanged = e => setUrl(e.target.value);
	const pitchChanged = e => setPitch(e.target.value);
	const canSend = () =>
		company.length > 0 &&
		addressStreet.length > 0 &&
		addressZip.length > 0 &&
		addressCountry.length > 0 &&
		addressCity.length > 0 &&
		email.length > 0 &&
		pitch.length > 0 &&
		url.length > 0;

	const formSent = e => {
		e.preventDefault();
		const res = enterRequest({
			company,
			addressStreet,
			addressZip,
			addressCountry,
			addressCity,
			phone,
			email,
			pitch,
			website: url
		});
		if (res) {
			setIsSent(true);
		} else {
			setIsSent(false);
		}
	};

	return (
		<BigBox>
			<H1 style={{ marginTop: 0, marginBottom: "0.5em", textAlign: "center" }}>
				{isSent ? "Vielen Dank!" : "Bewerbungsdaten"}
			</H1>
			<Content>
				{!isSent && (
					<FieldView>
						<FieldList>
							<Field>
								<FieldLabel htmlFor="company">
									Name des Unternehmens:*
								</FieldLabel>
								<DarkTB
									id="company"
									name="company"
									value={company}
									onChange={companyChanged}
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="addressStreet">
									Unternehmensadresse - Straße:*
								</FieldLabel>
								<DarkTB
									id="addressStreet"
									name="addressStreet"
									value={addressStreet}
									onChange={streetChanged}
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="addressZip">
									Unternehmensadresse - Postleitzahl:*
								</FieldLabel>
								<DarkTB
									id="addressZip"
									name="addressZip"
									value={addressZip}
									onChange={zipChanged}
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="addressCity">
									Unternehmensadresse - Stadt:*
								</FieldLabel>
								<DarkTB
									id="addressCity"
									name="addressCity"
									value={addressCity}
									onChange={cityChanged}
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="addressCountry">
									Unternehmensadresse - Land:*
								</FieldLabel>
								<DarkTB
									id="addressCountry"
									name="addressCountry"
									value={addressCountry}
									disabled
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="phone">Telefon (optional):</FieldLabel>
								<DarkTB
									id="phone"
									name="phone"
									value={phone}
									onChange={phoneChanged}
									type="tel"
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="email">E-Mail:*</FieldLabel>
								<DarkTB
									id="email"
									name="email"
									value={email}
									onChange={emailChanged}
									type="email"
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="url">Webseite / Shop:*</FieldLabel>
								<DarkTB
									id="url"
									name="url"
									value={url}
									onChange={urlChanged}
									type="url"
								/>
							</Field>
							<Field>
								<FieldLabel htmlFor="pitch">
									Wieso dieses Unternehmen?*
								</FieldLabel>
								<DarkTA
									id="pitch"
									name="pitch"
									cols="20"
									rows="5"
									value={pitch}
									onChange={pitchChanged}
								/>
							</Field>
							<ButtonField>
								<Button type="submit" disabled={!canSend()} onClick={formSent}>
									Absenden
								</Button>
							</ButtonField>
							<Field>
								<PrivacyText>
									Mit absenden des Formulars erklären Sie sich mit unserer{" "}
									<Link to="/datenschutz">Datenschutzvereinbarung</Link>{" "}
									einverstanden.
								</PrivacyText>
							</Field>
						</FieldList>
					</FieldView>
				)}
				{isSent && (
					<ThankBox>
						<div>
							<p>
								Ihre Bewerbung ist erfolgreich abgesendet worden. Wir werden die
								Daten sorgfältig prüfen. Sie werden in Kürze eine E-Mail
								erhalten, ob und wann ihr Unternehmen gelistet sein wird.
							</p>
						</div>
						<div>
							<p>
								Bitte haben Sie Verständnis, dass uns aktuell eine große Menge
								an Anfragen erreicht und wir deshalb nur bedingt auf direkt
								adressierte Mails reagieren können.
							</p>
						</div>
						<HamsterImage />
					</ThankBox>
				)}
			</Content>
		</BigBox>
	);
};

export default Request;
