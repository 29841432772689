import React from "react";
import styled, { keyframes, css } from "styled-components";

const pulseAnimation = keyframes`
 0% { background: #a6bdb0; }
 50% { background: rgba(146, 16, 85, 1); }
 100% { background: #a6bdb0; }
`;

const TB = styled.input`
	background: #a6bdb0;
	box-shadow: 2px 2px 4px rgba(143, 162, 151, 0.25);
	color: #47514b;
	padding: 0.5em;
	outline: none;
	border: none;
	border-radius: 0.25em;
	${({ isValid }) =>
		isValid
			? css`
					border: none;
			  `
			: css`
					border: 1px solid red;
			  `}
`;
const Wrapper = styled.div`
	background: #a6bdb0;
	box-shadow: 2px 2px 4px rgba(143, 162, 151, 0.25);
	color: #47514b;
	padding: 0.5em;
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	border-radius: 0.25em;
	${props =>
		props.pulse &&
		css`
			animation-name: ${pulseAnimation};
			animation-duration: 1.5s;
			animation-iteration-count: 2;
		`}
`;

const HR = styled.hr`
	height: 80%;
	border-left: 1px solid #f3fdf4;
	margin-right: 0.5em;
`;

const Textbox = ({
	iconPast = null,
	isValid = true,
	pulse = false,
	...rest
}) => {
	return (
		<Wrapper pulse={pulse}>
			<TB isValid={isValid} {...rest} />
			{iconPast && <HR />}
			{iconPast}
		</Wrapper>
	);
};

export default Textbox;
