import React from "react";
import PropTypes from "prop-types";
import "normalize.css";
import styled from "styled-components";
import GlobalStyles from "./GlobalStyles";
import Header from "./header";
import Footer from "./footer";

const MainWrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;
  padding: 0 1.0875rem 1.45rem;
  flex: 1;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Header siteTitle={`Hamster Shopping`} />
      <MainWrapper>
        <main>{children}</main>
      </MainWrapper>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
