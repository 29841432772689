import React from "react";
import styled from "styled-components";

const TB = styled.textarea`
	background: #a6bdb0;
	box-shadow: 2px 2px 4px rgba(143, 162, 151, 0.25);
	color: #47514b;
	padding: 0.5em;
	outline: none;
	border: none;
	border-radius: 0.25em;
	${({ isValid }) =>
		isValid
			? `
    border: none
  `
			: `border: 1px solid red;`}
`;
const Wrapper = styled.div`
	background: #a6bdb0;
	box-shadow: 2px 2px 4px rgba(143, 162, 151, 0.25);
	color: #47514b;
	padding: 0.5em;
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	border-radius: 0.25em;
`;

const HR = styled.hr`
	height: 80%;
	border-left: 1px solid #f3fdf4;
	margin-right: 0.5em;
`;

const Textarea = ({ iconPast = null, isValid = true, ...rest }) => {
	return (
		<Wrapper>
			<TB isValid={isValid} {...rest} />
			{iconPast && <HR />}
			{iconPast}
		</Wrapper>
	);
};

export default Textarea;
