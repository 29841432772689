import React from "react";
import styled from "styled-components";
import BigBox from "./BigBox";
import DarkBox from "./DarkBox";
import ReactMomentCountDown from "react-moment-countdown";
import moment from "moment";
import "moment/locale/de";

moment.locale("de");

const Content = styled.div`
	display: flex;
	align-items: flex-start;
	@media only screen and (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		margin-left: 1em;
		margin-right: 1em;
	}
`;

const ContactBox = styled.div`
	color: #000;
	line-height: 2rem;
	font-size: 0.875em;
	font-style: normal;
	font-weight: normal;
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: 1024px) {
		align-items: center;
		width: 100%;
		text-align: center;
	}
`;

const GridView = styled.div`
	display: grid;
	grid-template-columns: repeat(2, max-content);
	grid-auto-columns: min-content;
	grid-template-rows: 2rem;
	@media only screen and (max-width: 1024px) {
		text-align: left;
	}
`;

const PitchCaption = styled.h5`
	text-decoration-line: underline;
	font-weight: bold;
	text-align: center;
`;

const TimeBox = styled.div`
	display: flex;
	flex-direction: column;
	& > span {
		margin-top: 0.25em;
		color: #587062;
		font-size: 1.5em;
	}
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const Website = styled.a`
	word-break: break-word;
`;

const Listing = ({ item }) => {
	return (
		<BigBox>
			<h1 style={{ marginTop: 0, marginBottom: "0.5em", textAlign: "center" }}>
				{item.company}
			</h1>
			<Content>
				<ContactBox>
					<div>
						{item.addressStreet}
						<br />
						{`${item.addressZip} ${item.addressCity}`}
						<br />
						{item.addressCountry}
					</div>
					<br />
					<div>
						<span
							dangerouslySetInnerHTML={{ __html: item.openingtimes }}
						></span>
					</div>
					<br />
					<GridView>
						<div>Telefon:&nbsp;</div>
						<div>{item.phone}</div>
						<div>E-Mail:</div>
						<div>
							<a href={`mailto:${item.email}`}>{item.email}</a>
						</div>
					</GridView>
					<div>
						<Website href={item.website} target="_blank">
							{item.website}
						</Website>
					</div>
				</ContactBox>
				<DarkBox>
					<PitchCaption>Wieso hier?</PitchCaption>
					<p>{item.pitch}</p>
				</DarkBox>
				<TimeBox>
					<div style={{ whiteSpace: "nowrap" }}>Aktiv für</div>
					<ReactMomentCountDown
						toDate={moment(item.releaseDate).hour(23).minute(59).second(59)}
						targetFormatMask="HH:mm:ss"
					/>
					Stunden
				</TimeBox>
			</Content>
		</BigBox>
	);
};

export default Listing;
