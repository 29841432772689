import React, { useState } from "react";
import styled from "styled-components";
import { MdSend } from "react-icons/md";
import { Link } from "@reach/router";
import Layout from "../components/layout";
import Listings from "../components/Listings";
import SEO from "../components/seo";
import Textbox from "../components/Textbox";
import DescriptiveText from "../components/DescriptiveText";
import Button from "../components/Button";
import "moment/locale/de";
import moment from "moment";
import { registerNewsletter } from "../util/rest";
import { usePageValue } from "../util/pageContext";
import HamsterImage from "../components/HamsterImage";
import { useNavigate } from "@reach/router";

moment.locale("de");

const Hero = styled.h2`
	color: #4f5953;
	font-size: 1.5em;
	font-weight: normal;
	font-style: normal;
	line-height: 1.3125em;
	text-align: center;
	margin-top: 0;
`;

const FlexCentered = styled.div`
	display: flex;
	justify-content: center;
`;

const CenteredLinks = styled(FlexCentered)`
	font-size: 0.75em;
`;

const SignupComp = styled.div`
	display: flex;
	margin: 0;
	clear: both;
	margin-left: 5px;
`;

const HomePage = () => {
	const [pageContext, dispatch] = usePageValue();
	const navigate = useNavigate();
	const todayISO = `${moment()
		.subtract(1, "days")
		.format("YYYY-MM-DD")}T22:00:00.000Z`;
	const [email, setEmail] = useState("");
	const [zipcode, setZipcode] = useState("");

	const regNewsletter = () => {
		if (email && email.length > 4) {
			const result = registerNewsletter(email, zipcode);
			if (result) {
				dispatch({
					type: "NEWSLETTER_SIGNUP",
					payload: true
				});
			} else {
				dispatch({
					type: "NEWSLETTER_SIGNUP",
					payload: false
				});
			}
		}
	};

	const emailChanged = e => {
		setEmail(e.target.value);
	};

	const zipChanged = e => {
		setZipcode(e.target.value);
	};

	const navToReq = () => {
		navigate(`/anfrage`);
	};

	return (
		<Layout>
			<SEO title="Home" />
			<Hero>Hamstern bei Kleinunternehmen und diesen dadurch helfen!</Hero>
			<FlexCentered>
				<Textbox
					name="zipcode"
					placeholder="Stadt / Postleitzahl ..."
					value={zipcode}
					onChange={zipChanged}
					isValid={zipcode.length > 2 || zipcode.length === 0 || !zipcode}
					pulse={true}
				/>
			</FlexCentered>
			<Listings asOfDate={todayISO} filter={zipcode} />
			{pageContext && pageContext.nsu && (
				<DescriptiveText>Vielen Dank fürs Anmelden!</DescriptiveText>
			)}
			{(!pageContext || !pageContext.nsu) && (
				<DescriptiveText>
					Tägliche Empfehlung per E-Mail? Hier eintragen:{" "}
					<SignupComp>
						<Textbox
							name="email"
							type="email"
							value={email}
							onChange={emailChanged}
							placeholder="max.mustermann@yahoo.de"
							style={{ padding: "0.1em", paddingLeft: 0 }}
							iconPast={<MdSend className="micon" onClick={regNewsletter} />}
						/>
					</SignupComp>
				</DescriptiveText>
			)}
			<br />
			<FlexCentered>
				<Button onClick={navToReq}>Als Unternehmen bewerben</Button>
			</FlexCentered>
			<br />
			<FlexCentered>
				<HamsterImage />
			</FlexCentered>
			<br />
			<CenteredLinks>
				<Link style={{ paddingRight: "1em" }} to="impressum">
					Impressum
				</Link>
				<Link to="datenschutz">Datenschutz</Link> <br />
			</CenteredLinks>
		</Layout>
	);
};

export default HomePage;
