import React from "react";
import styled from "styled-components";

const Btn = styled.button`
	cursor: pointer;
	background: #587062;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 0.5em;
	font-style: normal;
	font-weight: normal;
	font-size: 1.125em;
	line-height: 1.3125em;
	color: #f3fdf4;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	&:disabled {
		color: #7d8a7f;
	}
`;

const Button = ({ children, ...rest }) => {
	return <Btn {...rest}>{children}</Btn>;
};

export default Button;
